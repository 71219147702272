import type {
  CustomPostReview,
  CustomPostReviewWithRoute,
  Endpoints,
  WithRoute,
} from '~/types'

export const useWaperOpinionsStore = defineStore('waperOpinionsStore', () => {
  const socialStore = useSocialStore()

  // STATE
  const waperOpinions = ref<CustomPostReviewWithRoute[]>([])
  const waperOpinion = ref<WithRoute<CustomPostReview>>()
  const userWaperOpinions = ref<CustomPostReviewWithRoute[]>([])
  const otherWaperOpinions = ref<CustomPostReviewWithRoute[]>([])
  const waperOpinionsPages = ref(1)
  const waperOpinionsCurrentPage = ref(1)
  const communityWaperOpinions = ref<CustomPostReviewWithRoute[]>([])
  const communityWaperOpinionsPages = ref(1)
  const communityWaperOpinionsCurrentPage = ref(1)
  const bannedIds = ref<number[]>([])

  // GETTERS
  const getBannedIds = computed(() => bannedIds.value)
  const getWaperOpinionId = computed(() => waperOpinion.value?.id)
  const getWaperOpinionCommunity = computed(
    () => waperOpinion.value?.community?.slug,
  )
  const getPagesCount = computed(() => waperOpinionsPages.value)
  const getCurrentPage = computed(() => waperOpinionsCurrentPage.value)
  const getCommunityPagesCount = computed(
    () => communityWaperOpinionsPages.value,
  )
  const getCommunityCurrentPage = computed(
    () => communityWaperOpinionsCurrentPage.value,
  )

  // ACTIONS

  const fetchWaperOpinions = async ({
    page = 1,
    search = '',
    sort = 'date',
  }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__REVIEWS_SEARCH']
    >(API.GET__REVIEWS_SEARCH, {
      query: {
        q: search,
        category_slug: 'avis-joueurs',
        page,
        banned_ids: getBannedIds.value,
        limit: 36,
        sort,
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      waperOpinions.value = []

      return logError(
        'from actions',
        'There was a problem fetching waperOpinions: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community?.slug}/avis-joueurs/detail/${element.slug}`,
    }))

    waperOpinions.value = formattedData
    waperOpinionsPages.value = rawData.pages_count
    waperOpinionsCurrentPage.value = page
  }

  const fetchCommunityWaperOpinions = async ({
    page = 1,
    community = '',
    search = '',
    sort = 'date',
  }) => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__REVIEWS_SEARCH']
    >(API.GET__REVIEWS_SEARCH, {
      query: {
        q: search,
        community_slug: community,
        category_slug: 'avis-joueurs',
        limit: 24,
        sort,
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      communityWaperOpinions.value = []
      return logError(
        'from actions',
        'There was a problem fetching waperOpinions',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community?.slug}/avis-joueurs/detail/${element.slug}`,
    }))
    communityWaperOpinions.value = formattedData
    communityWaperOpinionsPages.value = rawData.pages_count
    communityWaperOpinionsCurrentPage.value = page
  }

  const fetchUserWaperOpinions = async () => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__USER_COMMUNITIES_REVIEWS']
    >(API.GET__USER_COMMUNITIES_REVIEWS)

    const rawData = unref(data)
    if (error.value || !rawData) {
      userWaperOpinions.value = []
      return logError(
        'from actions',
        'There was a problem fetching user waperOpinions: ',
        error,
      )
    }

    const postIds: number[] = []
    const formattedData: CustomPostReviewWithRoute[] = []

    rawData.forEach((element) => {
      if (element.id) postIds.push(element.id)
      formattedData.push({
        ...element,
        route: `/communautes/${element.community?.slug}/avis-joueurs/detail/${element.slug}`,
      })
    })

    userWaperOpinions.value = formattedData
    bannedIds.value = postIds
  }

  const fetchWaperOpinion = async ({
    slug,
    community,
    category,
  }: {
    slug: string
    community: string
    category: string
  }) => {
    const { data, error } = await useCustomFetch<Endpoints['GET__REVIEW']>(
      `${API.GET__REVIEW}/${slug}`,
      {
        query: {
          community_slug: community,
          category_slug: category,
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      waperOpinion.value = undefined
      return logError(
        'from actions',
        'There was a problem fetching waperOpinions: ',
        error,
      )
    }

    const formattedComments = rawData.comments
      ? rawData.comments.map((comment) => ({
          ...comment,
          currentPage: 1,
          isOpen: false,
        }))
      : []

    const formattedData = {
      ...rawData,
      route: `/communautes/${rawData.community?.slug}/${
        rawData.category ? rawData.category.slug : 'nodata'
      }/detail/${rawData.slug}`,
    }

    socialStore.fetchSocial({
      slug,
      slugType: 'avis-joueurs',
      likes_count: rawData.likes_count ?? 0,
      is_liked: rawData.is_liked ?? false,
      shares_count: rawData.shares_count ?? 0,
      comments_count: rawData.comments_count ?? 0,
      comments: formattedComments,
      commentPage: 1,
      has_more_comments: rawData.has_more_comments ?? false,
      is_following: rawData.is_following ?? false,
    })

    waperOpinion.value = formattedData
    await fetchOtherWaperOpinions()
  }

  const fetchOtherWaperOpinions = async (subCategory = '') => {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__REVIEWS_SEARCH']
    >(API.GET__REVIEWS_SEARCH, {
      query: {
        banned_ids: getWaperOpinionId.value,
        category_slug: 'avis-joueurs',
        sub_category_slug: subCategory,
        community_slug: getWaperOpinionCommunity.value,
        page: '1',
        limit: '12',
        sort: 'date',
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      otherWaperOpinions.value = []

      return logError(
        'from actions',
        'There was a problem fetching other waperOpinions: ',
        error,
      )
    }

    const formattedData = rawData.results.map((element) => ({
      ...element,
      route: `/communautes/${element.community?.slug}/${
        element.category ? element.category.slug : 'nodata'
      }/detail/${element.slug}`,
    }))
    otherWaperOpinions.value = formattedData
  }

  return {
    // STATE
    waperOpinions,
    waperOpinion,
    userWaperOpinions,
    otherWaperOpinions,
    waperOpinionsPages,
    waperOpinionsCurrentPage,
    communityWaperOpinions,
    communityWaperOpinionsPages,
    communityWaperOpinionsCurrentPage,
    bannedIds,

    // GETTERS
    getPagesCount,
    getCurrentPage,
    getCommunityPagesCount,
    getCommunityCurrentPage,

    // ACTIONS
    fetchWaperOpinions,
    fetchCommunityWaperOpinions,
    fetchUserWaperOpinions,
    fetchWaperOpinion,
    fetchOtherWaperOpinions,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWaperOpinionsStore, import.meta.hot),
  )
}
