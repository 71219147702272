import { generateList } from './helpers'

export const days = generateList(1, 31)
export const months = generateList(1, 12)
export const years = generateList(1928, new Date().getUTCFullYear()).reverse()

export const PASSWORD_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[_@$€!={}()%#<>^+*?&\-\.\/\\])[\w\d@$€!={}()%#<>^+*?&\-\.\/\\]{8,}$/

export const API = {
  // AUTH
  GET__CONNECT_FACEBOOK: '/login_facebook_check',
  GET__CONNECT_GOOGLE: '/connect/google',
  GET__PASSWORD_VALIDITY: '/reset_password/validity',
  GET__REGISTER_VERIFY: '/register/verify',
  POST__PASSWORD_RESET: '/reset_password',
  POST__PASSWORD_TOKEN: '/reset_password_token',
  POST__REGISTER_TOKEN: '/register_token',
  POST__REGISTER: '/register',
  POST__REGISTER_GOOGLE: '/register/google',

  // USER
  DELETE__USER: '/secure/user',
  GET__PROFILE: '/user/profile',
  GET__USER: '/user',
  GET__SECURE_USER_WAPERS: '/secure/user/following',
  GET__USER_POSTS: '/secure/post/community?category_slug=', // Ex. API.GET_USER_POSTS + 'coin-des-wapers'
  GET__USER_SEARCH: '/user/search',
  GET__USER_WAPERS: '/user/following',
  POST__FOLLOW_WAPER: '/secure/user/following',
  POST__UNFOLLOW_WAPER: '/secure/user/unfollowing',
  PUT__UPDATE_DISPLAYED_BADGE: '/secure/update_displayed_badge',
  PUT__UPDATE_PASSWORD: '/secure/update_password',
  PUT__UPDATE_PROFILE: '/secure/update_profile',

  // NOTIFICATION
  GET__NOTIFICATION_COUNT: '/secure/notification/count',
  GET__NOTIFICATION_LAST: '/secure/notification/last',
  PUT__NOTIFICATION_READ_ALL: '/secure/notification/read',
  PUT__NOTIFICATION_READ: '/secure/notification/$id/read',

  // POST
  GET__POST_SEARCH: '/post/search',
  GET__POST: '/secure/post',
  GET__SECURE_POST_COMMUNITY: '/secure/post/community',
  GET__SUBCATEGORIES: '/category/subcategory?slug=', // Ex. API.GET_SUBCATEGORIES + 'coin-des-wapers'
  POST__SECURE_POST_BV: '/secure/post/bv-review',
  POST__SECURE_POST_CONTRIBUTE: '/secure/post/contribute',
  POST__SECURE_POST_SOURCE: '/secure/post/source',

  // REVIEWS
  GET__REVIEW_SECURE: '/secure/bv-review',
  GET__REVIEW: '/secure/bv-review',
  GET__REVIEWS_SEARCH: '/post/bv-review/search',
  GET__USER_COMMUNITIES_REVIEWS: '/secure/post/bv-review/community',

  // TAG
  GET__TAG_SEARCH: '/tag/search',
  POST__SECURE_TAG: '/secure/tag',

  // COMMUNITIES
  GET__ALL_GENDER_COMMUNITY: '/community/all_slugs?game_type=',
  GET__COMMUNITY_FOLLOWING: '/community/following',
  GET__COMMUNITY_GENDER_INFOS: '/game_type/',
  GET__COMMUNITY_POST_TYPES: '/community/post_types',
  GET__COMMUNITY_SEARCH: '/community/search',
  GET__COMMUNITY: '/secure/community',
  GET__SECURE_COMMUNITY_FOLLOWING: '/secure/community/following',
  GET__SECURE_COMMUNITY_NOT_FOLLOWING: '/secure/community/not_following',
  POST__FOLLOW_COMMUNITY: '/secure/community/following',
  POST__UNFOLLOW_COMMUNITY: '/secure/community/unfollowing',

  // GAME TYPE = universe
  GET__GAME_TYPE: '/game_type',
  // GET_OTHER_GAME_TYPES: '/game_type?banned_slug=',

  // OFFERS
  GET__OFFER: '/secure/offer',
  GET__OFFERS_SEARCH: '/offer/search',

  // ARTICLES = actualites wap
  GET__ARTICLE_SEARCH: '/article/search',
  GET__ARTICLE: '/article',

  // HIGHLIGHTED ARTICLES
  GET__HIGHLIGHTED_COMMUNITIES_ARTICLES: '/article/highlighted/communities',
  // SLIDER
  GET__SLIDER_DO_NOT_MISS: '/slider/do_not_miss',

  // CHALLENGES
  GET__CHALLENGE_SEARCH: '/challenge/search',
  GET__CHALLENGE: '/secure/challenge',
  GET__MONTHLY_CHALLENGES: '/secure/challenge/current_month',
  POST__CHALLENGE: '/secure/challenge',

  // CONTEST
  GET__CONTEST_SEARCH: '/contest/search',
  GET__CONTEST: '/contest',
  GET__CONTESTS: '/contests',
  GET__SECURE_CONTEST: '/secure/contest',
  POST__CONTEST_USER: '/contest/$slug/user',
  POST__SECURE_CONTEST_USER: '/secure/contest/$slug/user',

  // WALLCONTEST
  // GET__MONTHLY_WALLCONTEST: '/wall-contest/current_month',
  // GET__WALLCONTEST_SEARCH: '/wall-contest/search',
  GET__WALLCONTEST: '/secure/wall-contest',
  GET__WALLCONTESTPOST: '/secure/wall-contest-post',
  GET__WALLCONTESTPOSTS: '/secure/$slug/wcposts',
  POST__SECURE_WALLCONTEST: '/secure/post/wall-contest/$slug',
  POST__WALLCONTEST: '/post/wall-contest/$slug',

  // RECORDCONTEST
  // GET__MONTHLY_RECORDCONTEST: '/speed-contest/current_month',
  // GET__RECORDCONTEST_SEARCH: '/speed-contest/search',
  GET__RECORDCONTEST: '/speed-contest',
  GET__RECORDCONTESTPOSTS: '/$slug/scposts',
  GET__RECORDCONTESTTEAM: '/speed-contest-team',
  GET__SECURE_RECORDCONTEST_INVITATION:
    '/secure/speed-contest-invitation/$slug',
  POST__RECORDCONTEST: '/post/speed-contest/$slug',
  POST__SECURE_RECORDCONTEST: '/secure/post/speed-contest/$slug',

  // EVENT
  GET__EVENT: '/secure/event',
  POST__EVENT_USER: '/event/$slug/user',
  POST__SECURE_EVENT_USER: '/secure/event/$slug/user',

  // RANKING
  GET__RANKING_LIST: '/ranking/list',
  GET__RANKING_SEARCH: '/ranking/search',
  GET__RANKING_SECURE_USER: '/secure/ranking/user',
  GET__RANKING_TOP: '/secure/ranking/top',

  // REFERENTIAL
  GET__REFERENTIAL: '/referential',

  // CROSSCONTENT
  GET_CROSSCONTENT: '/content/crosscontent',

  // POPOUT
  GET__POPOUT: '/content/popout-popin',

  // BLACKLISTING/WHITELISTING
  GET__ALL0W_WEBSITE_CHECK: '/allow_website/check',
  GET__BAN_WEBSITE_CHECK: '/ban_website/check',

  // HOMEPAGE
  GET__HOMEPAGE: '/homepage',
  // PAGE
  GET__PAGE: '/page',

  // SEARCH
  GET__SEARCH: '/search/full',
  GET__SEARCH_AUTOCOMPLETE: '/search/autocomplete',

  // REPORT
  GET_WARN_TYPE: '/warn_type',
  POST_WARN: '/secure/$type/$slug/warn',

  // TOURNAMENTS
  GET__MONTHLY_TOURNAMENT: '/tournament/current_month',
  GET__TOURNAMENT_SEARCH: '/tournament/search',
  GET__TOURNAMENT: '/secure/tournament',
  POST__TOURNAMENT: '/secure/tournament',

  // META CONTENT
  GET_META_CONTENT: '/meta_content',

  // SERVER MIDDLEWARE
  POST_POLISH: '/polish',
} as const

export const ROUTES = {
  CONTEST_SIMPLE: 'jeu-concours',
  CONTEST_WALL: 'concours',
  CONTEST_RECORD: 'concours-classement',
} as const
