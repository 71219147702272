import type { useCustomMediaQuery } from '@/composables/useCustomMediaQuery'
import type { FetchError } from 'ofetch'
import type { RouteLocationNormalizedGeneric } from 'vue-router'
import type { Image, SourceType } from '~/types'

export const generateList = (first = 0, last = 0) => {
  const days = []

  for (let i = first; i <= last; i++) {
    days.push(`${i < 10 ? `0${i}` : i}`)
  }

  return days
}

export const formatNumber = (num?: string | number) => {
  if (num == undefined) return num

  const formattedNumber = num
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')

  return `${formattedNumber} pts`
}

export const formatRun = (num?: number, isSecondsFormat = false) => {
  if (num == undefined) return num

  if (isSecondsFormat) {
    const remainingSeconds = Math.floor(num / 1000)
    const minutes = Math.floor((remainingSeconds / 60) % 60)
    const secondes = Math.floor(remainingSeconds % 60)

    return `${minutes}m ${secondes.toString().padStart(2, '0')}s`
  }

  const min = Math.floor(num / (60 * 1000))

  const rest = num % (60 * 1000)

  const sec = Math.floor(rest / 1000)
  const ms = rest % 1000

  return `${min}m ${sec.toString().padStart(2, '0')}s ${ms
    .toString()
    .padStart(3, '0')}ms`
}

export const findYoutubeId = (str: string) => {
  const matches = str.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/(?:embed\/)?)([^\s&]+)/,
  )

  return matches ? matches[1] : null
}

export const findDailymotionId = (str: string) => {
  const matches = str.match(
    /(?:https?:\/{2})?(?:w{3}\.)?dai(?:lymotion)?\.(?:com|ly)\/(?:embed\/)?(?:video\/)?([^\s&]+)/,
  )

  return matches ? matches[1] : null
}

export const findVimeoId = (str: string) => {
  const matches = str.match(
    /(?:https?:\/{2})?(?:w{3}\.)?(?:player.)?vimeo\.com(?:\/video|\/)([^\s&]+)/,
  )

  return matches ? matches[1] : null
}

export const getVideoInfo = (videoUrl?: string) => {
  const defaultValue = {
    isYoutube: false,
    cover: '',
  }

  if (!videoUrl) return defaultValue

  const videoData = extractVideoId(videoUrl)

  if (!videoData) return defaultValue

  const isYoutube = videoData.source === 'youtube'

  return {
    isYoutube,
    cover: isYoutube
      ? videoData.id
      : buildEmbedUrl(videoData.source, videoData.id),
  }
}

export const extractVideoId = ((url?: string) => {
  if (!url) return
  const youtubeId = findYoutubeId(url)

  if (youtubeId) {
    return {
      source: 'youtube',
      id: youtubeId,
    } as const
  }

  const dailymotionId = findDailymotionId(url)

  if (dailymotionId) {
    return {
      source: 'dailymotion',
      id: dailymotionId,
    } as const
  }

  const vimeoId = findVimeoId(url)

  if (vimeoId) {
    return {
      source: 'vimeo',
      id: vimeoId,
    } as const
  }
}) satisfies (str: string) => { source: SourceType; id: string } | undefined

export const buildYoutubeEmbedUrl = (id: string | number) =>
  `https://www.youtube.com/embed/${id}`
export const buildDailymotionEmbedUrl = (id: string | number) =>
  `https://www.dailymotion.com/embed/video/${id}`
export const buildVimeoEmbedUrl = (id: string | number) =>
  `https://player.vimeo.com/video/${id}`

export const buildEmbedUrl = (
  source: SourceType | undefined,
  id: string | number | undefined,
) => {
  if (!source || !id) return undefined

  if (source === 'youtube') {
    return buildYoutubeEmbedUrl(id)
  }

  if (source === 'dailymotion') {
    return buildDailymotionEmbedUrl(id)
  }

  if (source === 'vimeo') {
    return buildVimeoEmbedUrl(id)
  }

  return undefined
}

export const linkify = (inputText?: string) => {
  let replacedText = inputText || ''

  // URLs starting with http:// or https://
  const replacePattern1 =
    // eslint-disable-next-line no-useless-escape
    /([^>"'])(\bhttps?:\/\/[\/\w\-+&@#%?=~_|!:,.;]*)(?![^[\/\w\-+&@#%?=~_|!:,.;]])/gim
  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  // Change email addresses to mailto:: links.
  // const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim

  replacedText = replacedText.replace(
    replacePattern1,
    '$1<a href="$2" target="_blank" rel="noopener noreferrer nofollow">$2</a> ',
  )

  // replacedText = replacedText.replace(
  //   replacePattern2,
  //   '$1<a href="https://$2" target="_blank" rel="noopener noreferrer nofollow">$2</a>',
  // )

  // replacedText = replacedText.replace(
  //   replacePattern3,
  //   '<a href="mailto:$1" target="_blank" rel="noopener noreferrer nofollow">$1</a>',
  // )

  return replacedText
}

export const isNumber = (value?: number): value is number =>
  typeof value === 'number'

//const UNIQUE_KEY_PROP = "__unique_key_prop__";
//const KEY_PREFIX = "__key_prefix__" + Date.now() + "_";
//let uid = 0;

export const isDefined = <T>(value: T | undefined): value is T =>
  value !== undefined

export const getImage = (
  item: MaybeRef<
    | {
        image_desktop?: Image
        image_mobile?: Image
        image_portrait?: Image
      }
    | undefined
  >,
  mediaQuery: ReturnType<typeof useCustomMediaQuery>,
) => {
  const rawItem = unref(item)
  const image = rawItem?.image_desktop?.url

  if (mediaQuery.isMobile.value || mediaQuery.isLargeMobile.value) {
    return rawItem?.image_mobile?.url ?? rawItem?.image_portrait?.url ?? image
  }
  return image
}

type MaybeRefOrComputed<T> = T | Ref<T> | ComputedRef<T>

export const uniqueBy = <T extends Record<string, unknown>>(
  array: MaybeRefOrComputed<T[]>,
  property: string,
) => {
  return computed(() => {
    const rawArray = unref(array)
    if (!rawArray.length) return rawArray

    const seenSlugs = new Set()
    return rawArray.filter((item) => {
      if (seenSlugs.has(item[property])) {
        return false
      }

      seenSlugs.add(item[property])
      return true
    })
  })
}

export const getURLPathname = (url: string) => new URL(url).pathname

export const createError404 = (error: FetchError) => {
  if (error.statusCode === 404) {
    throw createError({ statusCode: 404 })
  }
}

export const getPageQuery = (route: RouteLocationNormalizedGeneric) => {
  return route.query.page
    ? Number.parseInt(route.query.page as unknown as string, 10)
    : 1
}
